<template>
  <div id="courses">
    <div v-if="loading">
      <LottieAnimation
        path="lottie/loader.json"
        :width="loaderWidth"
        :speed="2"
      />
    </div>
    <div v-else>
      <header-component />
      <description-component />
      <course-characteristics-component />
      <courses-description-tabs-component />
      <courses-description-component />
      <!-- <v-img :src="cardPrototype" style="width: 90%; margin: 10pt auto" /> -->
      <contact-form />
      <chooseYourCDEComponent />
      <courses-wrapper-component />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import HeaderComponent from "../components/HeaderComponent.vue";
import descriptionComponent from "../components/descriptionComponent.vue";
import courseCharacteristicsComponent from "../components/courseCharacteristicsComponent.vue";
import coursesDescriptionTabsComponent from "../components/coursesDescriptionTabsComponent.vue";
import coursesDescriptionComponent from "../components/coursesDescriptionComponent.vue";
import contactForm from "../components/ContactForm.vue";
import chooseYourCDEComponent from "../components/chooseYourCDEComponent.vue";
import coursesWrapperComponent from "../components/coursesWrapperComponent.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  name: "CoursesView",

  title() {
    return `AHJ ENDE Diplomados`;
  },

  data: () => ({
    loading: true,
    cardPrototype: require("../assets/img/cardPrototype.png"),
  }),

  components: {
    LottieAnimation,
    HeaderComponent,
    descriptionComponent,
    courseCharacteristicsComponent,
    coursesDescriptionTabsComponent,
    coursesDescriptionComponent,
    contactForm,
    chooseYourCDEComponent,
    coursesWrapperComponent,
  },

  created() {
    this.getCoursesData();
    this.cde[["title"]] = "Ubica tu CDE";
    this.cde[["conditionalParagraph"]] = "";
    this.courseDescriptionTabs = [
      "¿De qué seré capaz?",
      "¿Dónde podré desarrollar mis habilidades?",
    ];
  },

  methods: {
    getCoursesData() {
      let value = this.$route.query.course;
      axios
        .get("/courses", {
          params: { search: value },
          headers: {},
        })
        .then((response) => {
          var data = response.data;
          var course = data[["courses"]][0];
          var planDescriptionArr = course[["planDescription"]].split("-");
          var temp = [];
          planDescriptionArr.shift();
          planDescriptionArr.forEach((element) => {
            temp.push(`${element}`);
          });
          console.log(planDescriptionArr);
          this.description = course[["description"]];
          this.courseDescription[["title"]] = course[["planTitle"]];
          this.courseDescription[["text"]] = temp;
          this.headerPhoto = course[["headerPhoto"]];
          this.courseDescription[["img"]] = course[["planPhoto"]];
          this.courseCharacteristics = [
            { name: "Duración", description: course[["duration"]] },
            {
              name: "Módulos",
              description: course[["modules"]],
            },
            {
              name:
                course[["name"]] === "Diplomado en Administración y negocios"
                  ? "Modalidad"
                  : "Horas Estimadas",
              description: course[["modality"]],
            },
          ];
          var nameStr = "";
          course[["name"]].split(/(?=[A-Z])/).forEach((element, idx) => {
            if (idx === 0) this.header[["firstText"]] = element;
            else nameStr += element;
          });
          this.header[["secondText"]] = nameStr;
        })
        .finally(() => {
          this.getSearchElements();
        });
    },

    getSearchElements() {
      axios
        .get("/highschool", {
          params: {},
          headers: {},
        })
        .then((response) => {
          var data = response.data;
          this.coursesWrapper = data[["highschool"]];
        })
        .finally(() => {
          this.loading = false;
          setTimeout(() => {
            this.setHeaderPhoto();
            this.setContactBackground();
            this.setDescriptionBackground();
          }, 100);
        });
    },

    setHeaderPhoto() {
      var header = window.document.getElementById("background-head");
      header.style.backgroundImage = `url(${this.headerPhoto})`;
    },

    setContactBackground() {
      var contact = window.document.getElementById("contactFormContainer");
      contact.style.background = "white";
    },

    setDescriptionBackground() {
      var description = window.document.getElementById("descriptionContainer");
      description.style.background = "black";
      description.style.color = "#c2c2c2";
    },
  },

  computed: {
    loaderWidth() {
      var windowWidth = window.innerWidth;
      return windowWidth > 300 ? 300 : windowWidth;
    },

    cde: {
      get() {
        return this.$store.getters["cdeModule/isCdeActive"];
      },
      set(newState) {
        if (this.$store.getters["cdeModule/isCdeActive"] !== newState) {
          this.$store.dispatch("cdeModule/toogleCdeState", newState);
        }
      },
    },

    courseCharacteristics: {
      get() {
        return this.$store.getters[
          "courseCharacteristicsModule/isCourseCharacteristicsActive"
        ];
      },
      set(newState) {
        if (
          this.$store.getters[
            "courseCharacteristicsModule/isCourseCharacteristicsActive"
          ] !== newState
        ) {
          this.$store.dispatch(
            "courseCharacteristicsModule/toogleCourseCharacteristicsState",
            newState
          );
        }
      },
    },

    courseDescription: {
      get() {
        return this.$store.getters[
          "courseDescriptionModule/isCourseDescriptionActive"
        ];
      },
      set(newState) {
        if (
          this.$store.getters[
            "courseDescriptionModule/isCourseDescriptionActive"
          ] !== newState
        ) {
          this.$store.dispatch(
            "courseDescriptionModule/toogleCourseDescriptionState",
            newState
          );
        }
      },
    },

    courseDescriptionTabs: {
      get() {
        return this.$store.getters[
          "courseDescriptionTabsModule/isCourseDescriptionTabsActive"
        ];
      },
      set(newState) {
        if (
          this.$store.getters[
            "courseDescriptionTabsModule/isCourseDescriptionTabsActive"
          ] !== newState
        ) {
          this.$store.dispatch(
            "courseDescriptionTabsModule/toogleCourseDescriptionTabsState",
            newState
          );
        }
      },
    },

    coursesWrapper: {
      get() {
        return this.$store.getters[
          "coursesWrapperModule/isCoursesWrapperActive"
        ];
      },
      set(newState) {
        if (
          this.$store.getters["coursesWrapperModule/isCoursesWrapperActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "coursesWrapperModule/toogleCoursesWrapperState",
            newState
          );
        }
      },
    },

    description: {
      get() {
        return this.$store.getters["descriptionModule/isDescriptionActive"];
      },
      set(newState) {
        if (
          this.$store.getters["descriptionModule/isDescriptionActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "descriptionModule/toogleDescriptionState",
            newState
          );
        }
      },
    },

    header: {
      get() {
        return this.$store.getters["headerModule/isHeaderActive"];
      },
      set(newState) {
        if (this.$store.getters["headerModule/isHeaderActive"] !== newState) {
          this.$store.dispatch("headerModule/toogleHeaderState", newState);
        }
      },
    },

    headerPhoto: {
      get() {
        return this.$store.getters["headerPhotoModule/isHeaderPhotoActive"];
      },
      set(newState) {
        if (
          this.$store.getters["headerPhotoModule/isHeaderPhotoActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "headerPhotoModule/toogleHeaderPhotoState",
            newState
          );
        }
      },
    },
  },
};
</script>
