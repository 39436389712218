const state = {
  contact: false,
};
const getters = {
  isContactActive(state) {
    return state.contact;
  },
};
const mutations = {
  changeContactState(state, contact) {
    state.contact = contact;
  },
};
const actions = {
  toogleContactState(context, contact) {
    context.commit("changeContactState", contact);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
