const state = {
  weAre: false,
};
const getters = {
  isWeAreActive(state) {
    return state.weAre;
  },
};
const mutations = {
  changeWeAreState(state, weAre) {
    state.weAre = weAre;
  },
};
const actions = {
  toogleWeAreState(context, weAre) {
    context.commit("changeWeAreState", weAre);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
