<template>
  <div id="blog">
    <v-dialog
      v-model="blogTextModal"
      width="800"
      style="max-height: 50%"
      transition="dialog-top-transition"
    >
      <v-card class="blogRead" style="z-index: 1000000000000000000000000">
        <v-container>
          <v-row>
            <v-col cols="2" style="margin: auto">
              <v-img
                src="https://res.cloudinary.com/interprocsysmex/image/upload/v1674512758/ahjende/landpage/Logo_Sin_Texto_sdcvcf.png"
                cover
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="11" style="margin: auto">
              <div class="componentSubtitle">
                {{ blogModalJSON[["name"]] }}
              </div>
              <div class="subtitleDelimiter" />
            </v-col>
            <v-col
              cols="10"
              style="margin: auto; border-bottom: 1px solid #0092b7"
            />
          </v-row>
          <v-row>
            <v-col
              cols="11"
              style="margin: auto; text-align: justify; padding: 25pt 0"
            >
              {{ blogModalJSON[["content"]] }}
            </v-col>
          </v-row>
        </v-container>
        <v-card-subtitle class="cardSubtitle">
          <v-btn
            class="newsBtn"
            text
            v-on:click="blogTextModal = !blogTextModal"
          >
            ¡Sigue explorando!
          </v-btn>
        </v-card-subtitle>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="blogModal"
      id="blogModal"
      width="800"
      transition="dialog-top-transition"
    >
      <v-card style="z-index: 1000000000000000000000000">
        <create-blog v-if="logged" />
        <v-container v-else>
          <v-row>
            <v-col cols="11" style="margin: auto">
              <div class="componentSubtitle">Ingresa a tu cuenta</div>
              <div class="subtitleDelimiter" />
            </v-col>
            <v-col cols="11" style="margin: auto">
              <v-text-field
                label="E-mail"
                placeholder="Ingresa tu correo"
                v-model="loginForm[['email']]"
                :rules="[rules.required, rules.email]"
                v-on:keyup.enter="login()"
              />
            </v-col>
            <v-col cols="11" style="margin: auto">
              <v-text-field
                label="Contraseña"
                :type="showPassword ? 'text' : 'password'"
                placeholder="Ingresa tu contraseña"
                :rules="[rules.required]"
                v-model="loginForm[['password']]"
                v-on:keyup.enter="login()"
              />
            </v-col>
            <v-col
              cols="11"
              class="descriptionText applyBtn"
              style="margin: auto"
            >
              <v-btn
                color="#0495b4"
                style="color: white"
                :loading="btnLoading"
                :disabled="btnLoading"
                v-on:click="login()"
              >
                Inicio de sesión
              </v-btn>
              &nbsp;&nbsp;&nbsp;
              <v-btn
                color="#868686"
                style="color: white"
                :disabled="btnLoading"
                v-on:click="blogModal = !blogModal"
              >
                Cerrar ventana
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="newsModal"
      id="newsModal"
      width="800"
      transition="dialog-top-transition"
    >
      <v-card style="z-index: 1000000000000000000000000">
        <create-news v-if="logged" />
        <v-container v-else>
          <v-row>
            <v-col cols="11" style="margin: auto">
              <div class="componentSubtitle">Ingresa a tu cuenta</div>
              <div class="subtitleDelimiter" />
            </v-col>
            <v-col cols="11" style="margin: auto">
              <v-text-field
                label="E-mail"
                placeholder="Ingresa tu correo"
                v-model="loginForm[['email']]"
                :rules="[rules.required, rules.email]"
                v-on:keyup.enter="login()"
              />
            </v-col>
            <v-col cols="11" style="margin: auto">
              <v-text-field
                label="Contraseña"
                :type="showPassword ? 'text' : 'password'"
                placeholder="Ingresa tu contraseña"
                :rules="[rules.required]"
                v-model="loginForm[['password']]"
                v-on:keyup.enter="login()"
              />
            </v-col>
            <v-col
              cols="11"
              class="descriptionText applyBtn"
              style="margin: auto"
            >
              <v-btn
                color="#0495b4"
                style="color: white"
                :loading="btnLoading"
                :disabled="btnLoading"
                v-on:click="login()"
              >
                Inicio de sesión
              </v-btn>
              &nbsp;&nbsp;&nbsp;
              <v-btn
                color="#868686"
                style="color: white"
                :disabled="btnLoading"
                v-on:click="newsModal = !newsModal"
              >
                Cerrar ventana
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <div v-if="loading">
      <LottieAnimation
        path="lottie/loader.json"
        :width="loaderWidth"
        :speed="1"
      />
    </div>
    <div v-else>
      <header-component />
      <div>
        <v-container>
          <v-row style="padding: 25pt 0" v-if="blogArr.length > 0">
            <v-col cols="11" md="6" style="margin: auto">
              <div class="componentSubtitle">Nuestros Blogs</div>
              <div class="subtitleDelimiter" />
            </v-col>
            <v-col cols="11" md="6" class="newCol" style="margin: auto">
              <v-btn icon v-on:click="blogModal = !blogModal">
                <v-icon color="#0092b7"> mdi-plus-circle </v-icon>
              </v-btn>
              <v-btn
                text
                color="#0092b7"
                style="text-transform: capitalize"
                v-on:click="blogModal = !blogModal"
              >
                Nuevo Blog
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="!blogLimit">
            <v-col cols="11" style="text-align: center; margin: auto">
              <v-btn icon v-on:click="prevBlog()">
                <v-icon color="#0092b7"> mdi-arrow-up </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="11"
              md="6"
              lg="4"
              style="margin: auto"
              class="allBlogs"
              v-for="(element, idx) in blogArr"
              :key="idx"
            >
              <v-card
                class="mx-auto"
                style="margin: auto; box-shadow: none"
                max-width="300"
              >
                <v-img
                  src="https://res.cloudinary.com/interprocsysmex/image/upload/v1674512758/ahjende/landpage/Logo_Sin_Texto_sdcvcf.png"
                  height="133px"
                  cover
                />
                <v-card-title style="color: #0092b7">
                  {{ element[["name"]] }}
                </v-card-title>
                <v-card-subtitle>
                  <v-container>
                    <v-row>
                      <v-col cols="6"> Por: {{ element[["author"]] }} </v-col>
                      <v-col cols="6" style="text-align: right">
                        {{ element[["joinedAt"]] }}
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-subtitle>
                <v-card-subtitle class="cardSubtitle">
                  <v-btn
                    class="newsBtn"
                    text
                    v-on:click="
                      (blogTextModal = !blogTextModal),
                        (blogModalJSON = element)
                    "
                  >
                    ¡Entérate!
                  </v-btn>
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="!blogPrevModal">
            <v-col cols="11" style="text-align: center; margin: auto">
              <v-btn icon v-on:click="nextBlog()">
                <v-icon color="#0092b7"> mdi-arrow-down </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="9"
              style="
                margin: auto;
                border-bottom: 2px solid rgba(0, 146, 183, 0.35);
              "
            />
          </v-row>
          <v-row style="padding: 25pt 0" v-if="newsArr.length > 0">
            <v-col cols="11" md="6" style="margin: auto">
              <div class="componentSubtitle">Nuestras Noticias</div>
              <div class="subtitleDelimiter" />
            </v-col>
            <v-col cols="11" md="6" class="newCol" style="margin: auto">
              <v-btn icon v-on:click="newsModal = !newsModal">
                <v-icon color="#0092b7"> mdi-plus-circle </v-icon>
              </v-btn>
              <v-btn
                text
                color="#0092b7"
                style="text-transform: capitalize"
                v-on:click="newsModal = !newsModal"
              >
                Nueva Noticia
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="!newsLimit">
            <v-col cols="11" style="text-align: center; margin: auto">
              <v-btn icon v-on:click="prevNews()">
                <v-icon color="#0092b7"> mdi-arrow-up </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="11"
              md="6"
              lg="4"
              style="margin: auto"
              v-for="(element, idx) in newsArr"
              :key="idx"
            >
              <v-card
                class="mx-auto"
                style="margin: auto; box-shadow: none"
                max-width="300"
              >
                <v-img :src="element[['image']]" height="133px" cover />
                <v-card-title style="color: #0092b7">
                  {{ element[["name"]] }}
                </v-card-title>
                <v-card-subtitle>
                  {{ element[["description"]] }}
                </v-card-subtitle>
                <v-card-subtitle
                  class="cardSubtitle"
                  v-on:click="goToLink(element[['link']])"
                >
                  <v-btn class="newsBtn" text target="_blank">
                    Conoce la noticia
                  </v-btn>
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="!newsPrevModal">
            <v-col cols="11" style="text-align: center; margin: auto">
              <v-btn icon v-on:click="nextNews()">
                <v-icon color="#0092b7"> mdi-arrow-down </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-container style="max-width: 100%; width: 100%">
        <v-row>
          <v-col
            cols="11"
            style="
              margin: auto;
              border-bottom: 2px solid rgba(0, 146, 183, 0.35);
              margin: auto;
            "
          />
        </v-row>
      </v-container>
      <contact-form />
      <chooseYourCDEComponent />
      <CDESChooserComponent />
      <courses-wrapper-component />
    </div>
  </div>
</template>

<script>
import HeaderComponent from "../components/HeaderComponent.vue";
import contactForm from "../components/ContactForm.vue";
import chooseYourCDEComponent from "../components/chooseYourCDEComponent.vue";
import CDESChooserComponent from "../components/CDESChooserComponent.vue";
import coursesWrapperComponent from "../components/coursesWrapperComponent.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import axios from "axios";
import rules from "../assets/js/rules";
import { required, email } from "vuelidate/lib/validators";
import createBlog from "../components/createBlogComponent.vue";
import createNews from "../components/createNewsComponent.vue";

var setLocationContainerPadding;

export default {
  name: "BlogView",

  title() {
    return `AHJ ENDE Blog y Noticias`;
  },

  data: () => ({
    btnLoading: false,
    loading: true,
    logged: false,
    blogModal: false,
    blogTextModal: false,
    newsModal: false,
    rules: rules,
    newsPage: 0,
    prevNewsPage: -3,
    nextNewsPage: 0,
    newsLimit: true,
    newsPrevModal: false,
    firstNewsLength: 0,
    isFirstNews: true,
    blogPage: 0,
    prevBlogPage: -3,
    nextBlogPage: 0,
    blogLimit: true,
    blogPrevModal: false,
    firstBlogLength: 0,
    isFirstBlog: true,

    nextNewsPush: false,
    nextBlogPush: false,

    showPassword: false,

    blogModalJSON: {},

    blogArr: [],
    newsArr: [],

    value: "",

    loginForm: {
      email: "",
      password: "",
    },
  }),

  watch: {
    reload() {
      if (this.reload === true) {
        this.reload = false;
        this.blogModal = false;
        this.blogTextModal = false;
        this.newsModal = false;
        this.getNewsElements();
      }
    },
  },

  components: {
    LottieAnimation,
    HeaderComponent,
    chooseYourCDEComponent,
    CDESChooserComponent,
    contactForm,
    coursesWrapperComponent,
    createBlog,
    createNews,
  },

  validations: {
    loginForm: {
      email: { required, email },
      password: { required },
    },
  },

  created() {
    this.cde[["title"]] = "Mapa de ubicaciones";
    this.cde[["conditionalParagraph"]] =
      "Ubica tu CDE (Centro de Desarrollo Empresarial)";
    this.header[["firstText"]] = "Blog y noticias";
    this.header[["secondText"]] = "AHJ ENDE";
    this.headerPhoto =
      "https://res.cloudinary.com/interprocsysmex/image/upload/v1685161728/ahjende/blognoticias/tony-blog_yxnu8j.jpg";
    this.blog = true;
    this.getNewsElements();
  },

  beforeDestroy() {
    clearInterval(setLocationContainerPadding);
  },

  destroyed() {
    this.blog = false;
  },

  methods: {
    goToLink(link) {
      window.open(link, "_blank");
    },

    getNewsElements() {
      axios
        .get("/news/elements-in-db")
        .then((response) => {
          this.nextNewsPage =
            Math.floor(parseInt(response.data.totalInDB) / 3) * 3;
          this.prevNewsPage = this.nextNewsPage - 3;
        })
        .finally(() => {
          this.getNews();
        });
    },

    async getNews() {
      this.newsPage = this.nextNewsPage;
      while (this.newsArr.length === 0)
        await axios
          .get(`/news?limit=3&skip=${this.newsPage}`, {
            params: { search: this.value },
          })
          .then((response) => {
            this.newsArr = response.data.news;
            this.firstNewsLength = this.newsArr.length;
            if (this.newsArr.length === 0) {
              this.nextNewsPage -= 3;
              this.prevNewsPage -= 3;
              this.newsPage -= 3;
            }
          })
          .finally(() => {
            if (this.newsArr.length !== 0) this.getBlogsElements();
          });
    },

    prevNews() {
      this.prevNewsPage += this.nextNewsPush ? 6 : 3;
      this.nextNewsPage += this.nextNewsPush ? 6 : 3;
      this.nextNewsPush = false;
      this.newsPage = this.nextNewsPage;
      this.newsPrevModal = false;
      axios
        .get(`/news?limit=3&skip=${this.newsPage}`, {
          params: { search: this.value },
          headers: {},
        })
        .then(async (response) => {
          var data = response.data;
          if (data[["news"]].length === 0) {
            this.prevNewsPage -= 3;
            this.nextNewsPage -= 3;
            this.newsLimit = true;
            this.isFirstNews = true;
            this.newsArr.splice(-3);
          } else {
            await data[["news"]].reverse().forEach((news) => {
              this.newsArr.unshift(news);
            });
            let treatedData =
              data[["news"]].length === this.firstNewsLength
                ? this.newsArr.slice(0, this.firstNewsLength + 3)
                : this.newsArr.slice(0, 6);
            this.newsArr = treatedData;
            this.newsLimit = false;
          }
        });
    },

    nextNews() {
      this.prevNewsPage -= 3;
      this.nextNewsPage -= 3;
      this.nextNewsPush = true;
      this.newsPage = this.nextNewsPage;
      axios
        .get(`/news?limit=3&skip=${this.newsPage}`, {
          params: { search: this.value },
          headers: {},
        })
        .then((response) => {
          var data = response.data;
          data[["news"]].forEach((news) => {
            this.newsArr.push(news);
          });
          var setConditional = this.isFirstNews
            ? this.newsArr.length > 3 + this.firstNewsLength
            : this.newsArr.length > 6;
          if (setConditional) {
            var toSplice = this.isFirstNews ? this.firstNewsLength : 3;
            this.newsArr.splice(0, toSplice);
            this.newsLimit = false;
            this.isFirstNews = false;
          }
          this.newsPrevModal = this.prevNewsPage > 0 ? false : true;
        });
    },

    getBlogsElements() {
      axios
        .get("/blog/elements-in-db", {
          params: {},
          headers: {},
        })
        .then((response) => {
          var data = response.data;
          this.nextBlogPage = Math.floor(parseInt(data[["totalInDB"]]) / 3) * 3;
          this.prevBlogPage = this.nextBlogPage - 3;
          console.log(data[["totalInDB"]]);
          console.log(this.prevBlogPage);
          console.log(this.nextBlogPage);
        })
        .finally(() => {
          this.getBlogs();
        });
    },

    async getBlogs() {
      this.blogPage = this.nextBlogPage;
      while (this.blogArr.length === 0)
        await axios
          .get(`/blog?limit=3&skip=${this.blogPage}`, {
            params: { search: this.value },
            headers: {},
          })
          .then((response) => {
            var data = response.data;
            this.blogArr = data[["blogs"]].reverse();
            this.firstBlogLength = this.blogArr.length;
            if (this.blogArr.length === 0) {
              this.nextBlogPage -= 3;
              this.prevBlogPage -= 3;
              this.blogPage -= 3;
            }
          })
          .finally(() => {
            if (this.blogArr.length !== 0) this.getSearchElements();
          });
    },

    prevBlog() {
      this.prevBlogPage += this.nextBlogPush ? 6 : 3;
      this.nextBlogPage += this.nextBlogPush ? 6 : 3;
      this.nextBlogPush = false;
      this.blogPage = this.nextBlogPage;
      this.blogPrevModal = false;
      axios
        .get(`/blog?limit=3&skip=${this.blogPage}`, {
          params: { search: this.value },
          headers: {},
        })
        .then(async (response) => {
          var data = response.data;
          if (data[["blogs"]].length === 0) {
            this.prevBlogPage -= 3;
            this.nextBlogPage -= 3;
            this.blogLimit = true;
            this.isFirstBlog = true;
            this.blogArr.splice(-3);
          } else {
            await data[["blogs"]].forEach((blog) => {
              this.blogArr.unshift(blog);
            });
            let treatedData =
              data[["blogs"]].length === this.firstBlogLength
                ? this.blogArr.slice(0, this.firstBlogLength + 3)
                : this.blogArr.slice(0, 6);
            this.blogArr = treatedData;
            this.blogLimit = false;
          }
        });
    },

    nextBlog() {
      this.prevBlogPage -= 3;
      this.nextBlogPage -= 3;
      this.nextBlogPush = true;
      this.blogPage = this.nextBlogPage;
      axios
        .get(`/blog?limit=3&skip=${this.blogPage}`, {
          params: { search: this.value },
          headers: {},
        })
        .then((response) => {
          var data = response.data;
          data[["blogs"]].reverse().forEach((blog) => {
            this.blogArr.push(blog);
          });
          var setConditional = this.isFirstBlog
            ? this.blogArr.length > 3 + this.firstBlogLength
            : this.blogArr.length > 6;
          if (setConditional) {
            var toSplice = this.isFirstBlog ? this.firstBlogLength : 3;
            this.blogArr.splice(0, toSplice);
            this.blogLimit = false;
            this.isFirstBlog = false;
          }
          this.blogPrevModal = this.prevBlogPage > 0 ? false : true;
        });
    },

    getSearchElements() {
      var end = ["/courses", "/highschool", "/careers"];
      var wrapperPos = ["courses", "highschool", "careers"];
      var rand = Math.floor(Math.random() * 3);
      axios
        .get(end[rand], {
          params: {},
          headers: {},
        })
        .then((response) => {
          var data = response.data;
          this.coursesWrapper = data[[wrapperPos[rand]]];
        })
        .finally(() => {
          this.loading = false;
          setTimeout(() => {
            this.setHeaderPhoto();
            this.setContactBackground();
            setLocationContainerPadding = setInterval(
              this.setLocationContainerPadding,
              100
            );
          }, 100);
        });
    },

    async login() {
      if (!this.$v.$invalid) {
        this.btnLoading = true;
        const rawResponse = await fetch(
          "https://ahjende-api-server.onrender.com/v1/stage/log/login",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(this.loginForm),
          }
        );
        const content = await rawResponse.json();
        this.logged = true;
        this.btnLoading = false;
        this.token = content[["admin"]];
      } else alert("Debes de rellenar el formulario para acceder a tu cuenta");
    },

    setHeaderPhoto() {
      var header = window.document.getElementById("background-head");
      header.style.backgroundImage = `url(${this.headerPhoto})`;
    },

    setContactBackground() {
      var contact = window.document.getElementById("contactFormContainer");
      contact.style.background = "#ffffff";
    },
  },

  computed: {
    loaderWidth() {
      var windowWidth = window.innerWidth;
      return windowWidth > 300 ? 300 : windowWidth;
    },

    cde: {
      get() {
        return this.$store.getters["cdeModule/isCdeActive"];
      },
      set(newState) {
        if (this.$store.getters["cdeModule/isCdeActive"] !== newState) {
          this.$store.dispatch("cdeModule/toogleCdeState", newState);
        }
      },
    },

    blog: {
      get() {
        return this.$store.getters["blogModule/isBlogActive"];
      },
      set(newState) {
        if (this.$store.getters["blogModule/isBlogActive"] !== newState) {
          this.$store.dispatch("blogModule/toogleBlogState", newState);
        }
      },
    },

    header: {
      get() {
        return this.$store.getters["headerModule/isHeaderActive"];
      },
      set(newState) {
        if (this.$store.getters["headerModule/isHeaderActive"] !== newState) {
          this.$store.dispatch("headerModule/toogleHeaderState", newState);
        }
      },
    },

    coursesWrapper: {
      get() {
        return this.$store.getters[
          "coursesWrapperModule/isCoursesWrapperActive"
        ];
      },
      set(newState) {
        if (
          this.$store.getters["coursesWrapperModule/isCoursesWrapperActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "coursesWrapperModule/toogleCoursesWrapperState",
            newState
          );
        }
      },
    },

    token: {
      get() {
        return this.$store.getters["tokenModule/isTokenActive"];
      },
      set(newState) {
        if (this.$store.getters["tokenModule/isTokenActive"] !== newState) {
          this.$store.dispatch("tokenModule/toogleTokenState", newState);
        }
      },
    },

    headerPhoto: {
      get() {
        return this.$store.getters["headerPhotoModule/isHeaderPhotoActive"];
      },
      set(newState) {
        if (
          this.$store.getters["headerPhotoModule/isHeaderPhotoActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "headerPhotoModule/toogleHeaderPhotoState",
            newState
          );
        }
      },
    },

    reload: {
      get() {
        return this.$store.getters["reloadModule/isReloadActive"];
      },
      set(newState) {
        if (this.$store.getters["reloadModule/isReloadActive"] !== newState) {
          this.$store.dispatch("reloadModule/toogleReloadState", newState);
        }
      },
    },
  },
};
</script>
