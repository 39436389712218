<template>
  <div id="privacy">
    <div v-if="loading">
      <LottieAnimation
        path="lottie/loader.json"
        :width="loaderWidth"
        :speed="1"
      />
    </div>
    <div v-else>
      <v-container>
        <v-row>
          <v-col cols="2" style="margin: auto">
            <v-img
              src="https://res.cloudinary.com/interprocsysmex/image/upload/v1674512758/ahjende/landpage/Logo_Sin_Texto_sdcvcf.png"
              cover
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="11" style="margin: auto">
            <div class="componentSubtitle">Nuestro Aviso de Privacidad</div>
            <div class="subtitleDelimiter" />
          </v-col>
          <v-col
            cols="10"
            style="margin: auto; border-bottom: 1px solid #0092b7"
          />
        </v-row>
        <v-row>
          <v-col
            cols="9"
            style="
              margin: auto;
              text-align: justify;
              padding: 25pt 0;
              font-weight: 300;
            "
          >
            <p>
              <strong
                >AHJ, INSTITUTO DCK, PARA LA FORMACION PROFESIONAL Y DE
                NEGOCIOS, S.C.</strong
              >
              (en lo sucesivo el “Responsable”), con domicilio en: Av. Morelos
              10, San Cristóbal Centro, 55000 Ecatepec de Morelos, Mex. y cuya
              dirección en la página web es www.ahjende.com, cuyo(s) número(s)
              telefónico(s) de contacto es(son) el(los) siguiente(s):
            </p>
            <br />
            <ul>
              <li>Ecatepec 55 1627 1320</li>
              <li>Cuautitlán 55 8567 3027</li>
              <li>Naucalpan 56 2436 3683</li>
              <li>Pachuca 77 1299 1696</li>
              <li>Querétaro 55 4820 6760</li>
              <li>San Luis Potosí 44 4664 2272</li>
            </ul>
            <br />
            <p>
              Toda vez que el Responsable, capta diversos datos personales,
              tanto de nuestros alumnos, prospectos de clientes, padres de
              familia, como de empleados (personal administrativo, docente, área
              comercial, supervisores), proveedores (contadores, abogados,
              asesores, auditores, expositores, y todo aquel ya sea persona
              física o moral que nos presta y otorga un servicio), entre otros;
              mediante el presente Aviso de Privacidad, se establecen los
              términos respecto del tratamiento y uso de la información que se
              recaba y recibe de manera física, así como aquella que se recibe
              de forma digital y/o encriptada, incluyendo las imágenes y videos
              imágenes que se capta a través de las cámaras fotográficas y de
              video que se tienen en las instalaciones del Responsable.
            </p>
            <br />
            <p>
              Toda la información que se recibe, capta y resguarda, tanto de
              forma física, como digital o de imagen y video, ya sea que esta
              corresponda a “información sensible” o “información no sensible”,
              se la dará el tratamiento debido apegándose el Responsable a lo
              previsto en la Ley Federal de Protección de Datos Personales en
              Posesión de Particulares (en adelante la Ley), su Reglamento, los
              Lineamientos del Aviso de Privacidad y demás disposiciones
              complementarias.
            </p>
            <br />
            <p>
              Dicha información se le dará el tratamiento única y exclusivamente
              para los fines requeridos por el Responsable, con el compromiso de
              mantener la absoluta confidencialidad de dicha información,
              obligándose a no difundir, distribuir, a terceros, ni dar un uso
              diverso al que corresponde para los fines que se requieran de
              dicha información, así como lo previsto en el presente Aviso de
              Privacidad.
            </p>
            <br />
            <p>
              La información que se recabe, o capte tanto físicamente como de
              forma digital, así como aquella que corresponda a imágenes o
              videos que se capte través de las cámaras se tendrán bajo
              resguardo en el domicilio arriba indicado, siendo que en el mismo
              domicilio se tiene un servidor el cual cuenta con las medidas de
              seguridad respectivas para que no se viole, transgreda y con un
              sistema anti hackeo, de la cual solo tendrá acceso el personal
              autorizado, quienes a su vez tienen suscrito un convenio de
              confidencialidad con el Responsable, con las restricciones
              respectivas para que a su vez, estos no puedan transgredir,
              violar, copiar no difundir sin la debida autorización del
              Responsable o del Titular de dicha información y solo podrán darle
              el uso que corresponde para los fines que haya sido captada dicha
              información conforme lo previsto en el presente Aviso de
              Privacidad. En el que el servidor donde se concentra la
              información se le deba dar un mantenimiento, se tomarán las
              medidas necesarias, y/o bien se migre la información de servidor,
              se acuerda que se dará aviso con anticipación de 30 días, al
              titular de los Derechos, quedando obligada el Responsable a cuidar
              que no se transgreda, o genere una copia de forma indebida.
            </p>
            <br />
            <p>
              El Responsable una vez que recabe, capte la información sensible o
              no sensible, imágenes o videos (en lo sucesivo Datos Personales),
              les dará el uso de acuerdo a lo que se señala a continuación:
            </p>
            <br />
            <p style="font-weight: 800">
              1) Alumnos, prospectos o interesados en estudiar en las
              instalaciones del Responsable y/o sus filiales.
            </p>
            <br />
            <p style="padding-left: 30px">
              Se recaba información sensible como no sensible, correspondiente a
              domicilio, teléfonos de contacto, tipo de sangre, posibles
              enfermedades o padecimientos. Dicha información será utilizada
              para fines de su inscripción, seguimiento del avance en sus
              estudios, así como para fines de la expedición de su certificado,
              así como también para fines de contacto para cualquier emergencia
              y/o atención médica ante cualquier emergencia que se llegare a
              presentar. También se podrá utilizar dicha información para fines
              de marketing o comercialización, mercadotécnicos, publicitarios,
              y/o de prospección comercial de nuevos productos o servicios
              educativos que ofrece el Responsable que puedan resultarle de
              utilidad o de interés al titular.
            </p>
            <br />
            <p style="padding-left: 30px">
              Asimismo, dichos Datos Personales serán utilizados para aquellos
              fines que sean necesarias para el servicio solicitado, o que
              permiten y faciliten brindarle un mejor servicio y atención, por
              lo que podrán hacerse encuestas de servicio o de nuevos servicios
              o productos que pudiera ser de interés del Titular de los Datos
              Personales.
            </p>
            <br />
            <p style="font-weight: 800">2) Empleados y/o candidatos.</p>
            <br />
            <p style="padding-left: 30px">
              Ya sea que se trate de personal administrativo, docente, área
              comercial, o supervisores, directores, coordinadores o encargados
              en la Institución Educativa de el Responsable o alguna filial a
              ésta.
            </p>
            <br />
            <p style="padding-left: 30px">
              Se recaba tanto información sensible, como no sensible, para
              efectos de su registro y alta ante el IMSS, INFONAVIT, así como
              para fines fiscales con el SAT, pagos de nómina, emisión del
              contrato individual de trabajo, convenio de confidencialidad,
              Aviso de Privacidad y Proceso por el uso de información a la que
              tiene acceso, con las debidas restricciones y sanciones en caso de
              violación y transgresión de la información a la que tiene acceso,
              que se suscribe para seguridad de la Empresa como del titular de
              la información de los Datos Personales que se recaban. También se
              cuenta con información sensible, para fines de emergencia,
              atención médica y/o cualquier tipo de tratamiento especial que
              deba corresponder por parte del Responsable.
            </p>
            <br />
            <p style="padding-left: 30px">
              Se podrá recopilar información a través de encuestas,
              cuestionarios, tanto para conocer de padecimiento médicos para una
              debida atención, así como también para mejorar los servicios que
              otorga del Responsable, incluidos aquellos que se requirieran para
              fines del cumplimiento de la NOM-035-STPS-2018 o cualquier otra
              norma, o disposiciones legales.
            </p>
            <br />
            <p style="font-weight: 800">
              3) Proveedores o posibles interesados.
            </p>
            <br />
            <p style="padding-left: 30px">
              Se recaba tanto información sensible, como no sensible, para
              efectos del registro en base de datos, suscripción de contratos,
              emisión de facturas, pagos de los servicios que se otorgan y/o
              reciben por el Responsable, en aquellos caso en los que el
              proveedor tenga acceso a información de Datos Personales, de los
              alumnos o prospectos y/o empleados de ésta última, se establecerá
              de forma clara las restricciones y uso de la información o Datos
              Personales de los que tenga acceso, en función de los términos
              contenidos en el presente Aviso de Privacidad, así como se
              establecerá de forma clara el Proceso al que se deberá sujetar
              para fines de la confidencialidad y resguardo de los Datos
              Personales, imagen o videos a los que tenga acceso, con las
              debidas restricciones y sanciones en caso de violación y
              transgresión de la información a la que tiene acceso.
            </p>
            <br />
            <p style="font-weight: 800">
              4) Imágenes o Videos en cámaras fotográficas o de vigilancia a
              través de circuito cerrado.
            </p>
            <br />
            <p style="padding-left: 30px">
              Se recopila información de imágenes o videos, para fines de
              expedición de las credenciales que identifiquen a un alumno o al
              personal contratado por el Responsable, en el caso de las cámaras
              de circuito cerrado, se utilizará única y exclusivamente para
              fines de seguridad y resguardo de los bienes de el Responsable, de
              los alumnos y del personal, sin que pueda ser difundido, copiado
              para otro fin diverso, dicha información permanecerá, por un
              máximo de 30 días y se borrará, con excepción de las fotografías
              que deban obrar para la expedición de credenciales o certificados.
            </p>
            <br />
            <p style="padding-left: 30px">
              Le informamos que sus datos personales pueden ser transferidos por
              el Responsable dentro y fuera del país única y exclusivamente
              entre las empresas filiales de éste último.
            </p>
            <br />
            <p style="padding-left: 30px">
              El Responsable se compromete a que todos los Datos Personales
              obtenidos serán tratados bajo las más estrictas medidas de
              seguridad que garanticen su confidencialidad.
            </p>
            <br />
            <p style="padding-left: 30px">
              Cuando la información deba ser recaba en cumplimiento de las
              disposiciones legales, misma que deberá mantenerse por dichos
              efectos ya sea un plazo de 5 o 10 años, tratándose de leyes
              fiscales, civiles o mercantiles en las que aplique la prescripción
              o caducidad, por lo que para dichos efectos y en cumplimiento a
              esas disposiciones, habrá información en la que no podrá operar la
              oposición y/o cancelación de la información que se tenga bajo
              resguardo, no obstante, el Responsable, se compromete a mantener
              dicha información con las medidas de seguridad necesarias para
              evitar su difusión y transgresión a la misma.
            </p>
            <br />
            <p style="padding-left: 30px">
              Para efectos de cualquier actualización o rectificación, oposición
              o cancelación de la información y/o datos que se tengan por parte
              de el Responsable, el titular de la misma podrá ponerse en
              contacto con el Responsable, ya sea en el domicilio, o en el(los)
              teléfono(s) arriba indicados, para el debido seguimiento, en caso
              de ser necesario se le solicitará presente por escrito su
              petición.
            </p>
            <br />
            <p style="padding-left: 30px">
              El Responsable deberá notificar al titular de la información, el
              resultado del ejercicio de los derechos de Acceso, Rectificación,
              Cancelación, u Oposición (derechos ARCO), por parte de los
              titulares de estos, a más tardar dentro del plazo aquí previsto, a
              efecto de que el Responsable realice lo conducente en los
              dispositivos donde se encuentren almacenados los datos en
              cuestión.
            </p>
            <br />
            <p style="padding-left: 30px">
              Los datos Personales serán utilizados exclusivamente para los
              fines mencionados en el presente aviso. En caso que usted no desee
              que se traten sus datos personales para los fines que no sean
              estrictamente necesarios para brindarle el servicio y /o atención
              solicitados, entre ellos fines publicitarios o para enviarle
              ofertas, usted lo debe hacer saber al Responsable.
            </p>
            <br />
            <p style="padding-left: 30px">
              De conformidad con la Ley, requerimos de su conocimiento expreso
              para el tratamiento de sus Datos Personales de carácter
              “financiero y patrimonial”. Si usted no manifiesta su oposición
              para el tratamiento de sus datos Personales de carácter financiero
              y patrimonial, se entenderá que otorga al Responsable su
              consentimiento expreso para ello. En todo caso, el Responsable se
              compromete a no tratar estos Datos Personales para finalidades
              diversas de las apuntadas en este Aviso de Privacidad, salvo en el
              caso de las excepciones prevista en la Ley.
            </p>
            <br />
            <p style="padding-left: 30px">
              Cualquier solicitud o trámite, referente a la Actualización,
              Rectificación, Cancelación u Oposición de los Datos Personales,
              deberá necesariamente presentarse por escrito, adjuntando copia de
              la identificación que acredite al titular, y/o poder notarial con
              la identificación del apoderado que acredita la legitimidad de los
              derechos del titular, señalando un correo electrónico y número de
              teléfono para que se le pueda contactar y deberá de forma clara y
              precisa describir en la solicitud la petición que dirige a el
              Responsable, para su debida atención, respecto de lo que pretende
              ejercer de los Derechos ARCO y de cualquier otro elemento que nos
              permita identificar o localizar los datos personales en cuestión.
            </p>
            <br />
            <p style="padding-left: 30px">
              Asimismo, le informamos que es posible que el Responsable no pueda
              cancelar o bloquear sus datos personales de conformidad con lo
              dispuesto por la Ley.<br /><br />
              Una vez recibida su solicitud completa y debidamente integrada, el
              Responsable le comunicará la respuesta correspondiente en un plazo
              máximo de 10 días hábiles.<br /><br />
              Si resultare procedente, dentro de los siguientes 15 días hábiles
              podrá hacer efectivo su derecho solicitado, directamente o a
              través de tercero facultado para dichos efectos.
            </p>
            <br />
            <p style="padding-left: 30px">
              El titular de los Datos Personales, podrá en cualquier momento
              revocar el consentimiento que nos ha otorgado para impedir total o
              parcialmente el uso, conservación y tratamiento de su información,
              sin embargo, es importante que tenga en cuenta que no en todos los
              casos podremos atender su solicitud o borrar de nuestra base de
              datos o cancelar el uso de forma inmediata, ya que es posible que
              por alguna obligación de las disposiciones legales u orden
              judicial, no podamos concretar la petición que realice.<br /><br />
              Los datos que no sean utilizados para recibir publicidad o
              promociones de empresas de bienes y servicios (para mayor
              información sobre este registro, usted puede consultar el portal
              de internet de la PROFECO, o bien ponerse en contacto directo con
              ésta).
            </p>
            <br />
            <p style="padding-left: 30px">
              El Responsable, por medio de la presente, le informa que en su
              página de internet utiliza cookies y/o otras tecnologías a través
              de las cuales es posible monitorear su comportamiento como usuario
              de internet, brindarle un mejor servicio y experiencia de usuario
              al navegar en nuestra página. Así como ofrecerles nuevos productos
              y servicios.<br /><br />
              En el caso de que existan vulneraciones de los datos personales
              que alguna de las partes le haya entregado a la otra para el
              cumplimiento del objeto del presente contrato, deberá informar
              inmediatamente a la otra parte que ha ocurrido dicha vulneración
              para que la parte afectada adopte las medidas y/o acciones
              correspondientes.
            </p>
            <br />
            <p style="padding-left: 30px">
              En virtud de lo anterior, en este acto cada una de las partes se
              obligan a sacar en paz y a salvo, y a indemnizar en su caso, a la
              otra parte afectada de cualquier acción, reclamación o
              procedimiento de cualquier naturaleza cuyo origen se encuentre en
              el incumplimiento respecto a la LFPDPPP y su Reglamento,
              relacionados con el tratamiento de datos personales ya sea que el
              procedimiento, sea iniciado por una autoridad o por un particular,
              dicha indemnización incluirá el reembolso de todos los gastos en
              los que la parte afectada hubiere incurrido en su defensa o manejo
              del procedimiento, incluyendo sin limitación honorarios de
              abogados y sanciones económicas.
            </p>
            <br />
            <p style="padding-left: 30px">
              Si el Responsable, y/o cualquiera de sus empleados o proveedores
              que tenga acceso a sus Datos Personales, incumple con lo dispuesto
              en el presente Aviso de Privacidad referente a la protección de
              los datos personales, se sujetará a lo establecido en el artículo
              6 y 67 y demás relativos y aplicables de la Ley Federal de
              Protección de Datos Personales en Posesión de los Particulares,
              además de ser responsable de los daños y perjuicios que se
              ocasionen al titular de los Datos Personales, como consecuencia
              del incumplimiento doloso o culposo de sus actos ya sea por
              descuido, negligencia ante el incumplimiento de la
              confidencialidad y resguardo de la información a la que tuvo
              acceso.
            </p>
            <br />
            <p style="padding-left: 30px">
              El Responsable, se compromete a establecer los mecanismos de
              seguridad para que no se infiltren terceras personas y no se les
              de acceso a ningún tercero ajeno, teniendo acceso solo su personal
              y proveedor, de acuerdo con los perfiles y parámetros y
              limitaciones que éste le indique de cada uno de los empleados que
              les permita el acceso.<br /><br />
              En caso de alguna modificación y/o actualización al presente Aviso
              de Privacidad, se dará aviso al titular de los derechos con 30
              días de anticipación, para los fines, que en su caso convengan.
            </p>
            <br />
            <p style="font-weight: 600; text-align: center">
              AHJ, INSTITUTO DCK, PARA LA FORMACION PROFESIONAL Y DE NEGOCIOS,
              S.C.
            </p>
            <p style="text-align: center">
              Ecatepec, Estado de México a 16 de abril de 2023.
            </p>
          </v-col>
        </v-row>
      </v-container>
      <v-card-subtitle class="cardSubtitle" v-on:click="closePage()">
        <v-btn class="newsBtn" text> Cerrar </v-btn>
      </v-card-subtitle>
    </div>
  </div>
</template>

<script>
import LottieAnimation from "/node_modules/lottie-vuejs/src/LottieAnimation";

export default {
  name: "PrivacyView",

  title() {
    return `AHJ ENDE Aviso de Privacidad`;
  },

  data: () => ({
    loading: true,
  }),

  components: {
    LottieAnimation,
  },

  created() {
    this.privacy = true;
  },

  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },

  beforeDestroy() {
    this.privacy = false;
  },

  methods: {
    closePage() {
      this.goToRoute("/", "#index");
    },

    goToRoute(a, route) {
      this.$router.push(a);
      setTimeout(() => this.goTo(route, { duration: 300 }), 200);
    },

    goTo(target, options) {
      this.$vuetify.goTo(target, options);
    },
  },

  computed: {
    loaderWidth() {
      var windowWidth = window.innerWidth;
      return windowWidth > 300 ? 300 : windowWidth;
    },

    privacy: {
      get() {
        return this.$store.getters["privacyModule/isPrivacyActive"];
      },
      set(newState) {
        if (this.$store.getters["privacyModule/isPrivacyActive"] !== newState) {
          this.$store.dispatch("privacyModule/tooglePrivacyState", newState);
        }
      },
    },
  },
};
</script>
