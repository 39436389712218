const state = {
  token: "",
};
const getters = {
  isTokenActive(state) {
    return state.token;
  },
};
const mutations = {
  changeTokenState(state, token) {
    state.token = token;
  },
};
const actions = {
  toogleTokenState(context, token) {
    context.commit("changeTokenState", token);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
