import Vue from "vue";
import App from "./App.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import titleChange from "./titleChange/titleChange";
import axios from "axios";
import vuelidate from "vuelidate";
import VueTheMask from "vue-the-mask";

Vue.mixin(titleChange);

Vue.config.productionTip = false;

Vue.use(LottieAnimation);
Vue.use(vuelidate);
Vue.use(VueTheMask);

axios.defaults.baseURL = "https://ahjende-api-server.onrender.com/v1/stage";

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
