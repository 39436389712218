const state = {
  courseDescriptionTabs: [],
};
const getters = {
  isCourseDescriptionTabsActive(state) {
    return state.courseDescriptionTabs;
  },
};
const mutations = {
  changeCourseDescriptionTabsState(state, courseDescriptionTabs) {
    state.courseDescriptionTabs = courseDescriptionTabs;
  },
};
const actions = {
  toogleCourseDescriptionTabsState(context, courseDescriptionTabs) {
    context.commit("changeCourseDescriptionTabsState", courseDescriptionTabs);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
