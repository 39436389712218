export default [
  {
    policyText: "1. Enfoque al cliente",
  },
  {
    policyText: "2. Liderazgo",
  },
  {
    policyText: "3. Compromiso de las personas",
  },
  {
    policyText: "4. Enfoque basado en procesos",
  },
  {
    policyText: "5. Mejora",
  },
  {
    policyText: "6. Toma de decisiones basadas en evidencia",
  },
  {
    policyText: "7. Gestión de las relaciones",
  },
];
