<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="11" style="margin: auto">
          <div class="componentSubtitle">Crea un Blog</div>
          <div class="subtitleDelimiter" />
        </v-col>
        <v-col
          cols="11"
          style="margin: auto; font-weight: 300"
          v-for="(key, idx) in Object.keys(blog)"
          :key="idx"
        >
          <v-text-field
            :label="textField[idx][['label']]"
            :placeholder="textField[idx][['placeholder']]"
            :rules="[rules.required]"
            :disabled="btnDisabled"
            v-model="blog[[key]]"
            v-on:keyup.enter="postBlog()"
            v-if="key !== 'content' && key != 'category'"
          />
          <v-textarea
            :label="textField[idx][['label']]"
            :placeholder="textField[idx][['placeholder']]"
            :rules="[rules.required]"
            :disabled="btnDisabled"
            v-model="blog[[key]]"
            counter="1600"
            v-on:keyup.enter="postBlog()"
            v-if="key === 'content'"
          />
          <v-select
            label="Categoría"
            :rules="[rules.required]"
            :disabled="btnDisabled"
            clearable
            v-model="blog[[key]]"
            item-text="category"
            item-value="category"
            :items="items"
            open-on-clear
            single-line
            v-if="key === 'category'"
          />
        </v-col>
        <v-col
          cols="11"
          style="text-align: left; font-weight: 300; font-size: small"
        >
          <span class="ml-8">
            * Este contenido se mostrará p&uacute;blicamente, ten cuidado con lo
            que compartas.
          </span>
        </v-col>
        <v-col cols="11" class="descriptionText applyBtn" style="margin: auto">
          <v-btn
            color="#0495b4"
            style="color: white"
            block
            :loading="btnLoading"
            :disabled="btnDisabled"
            v-on:click="postBlog()"
          >
            Publicar Blog
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import rules from "../assets/js/rules";
import { required } from "vuelidate/lib/validators";

export default {
  name: "createBlogComponent",

  data: () => ({
    rules: rules,
    btnLoading: false,

    textField: [
      {
        label: "Título del Blog",
        placeholder: "Ingresa el título",
      },
      {
        label: "Categoría",
        placeholder: "Ingresa la categoría",
      },
      {
        label: "Contenido",
        placeholder: "Ingresa el contenido",
      },
    ],

    items: [
      {
        category: "Negocios",
      },
      {
        category: "Ventas",
      },
      {
        category: "Sociales",
      },
      {
        category: "Educación",
      },
      {
        category: "Tecnología",
      },
    ],

    blog: {
      name: "",
      category: "",
      content: "",
    },
  }),

  validations: {
    blog: {
      name: { required },
      category: { required },
      content: { required },
    },
  },

  methods: {
    async postBlog() {
      if (!this.$v.$invalid) {
        this.btnLoading = true;
        this.btnDisabled = true;
        const rawResponse = await fetch(
          "https://ahjende-api-server.onrender.com/v1/stage/blog/register",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: this.token,
            },
            body: JSON.stringify(this.blog),
          }
        );
        const content = await rawResponse.json();
        this.btnLoading = false;
        this.btnDisabled = false;
        this.blog = {
          name: "",
          category: "",
          content: "",
        };
        console.log(content);
        this.reload = true;
        location.reload();
      } else alert("No es posible continuar con espacios en blanco");
    },
  },

  computed: {
    btnDisabled: {
      get() {
        return this.$store.getters["btnDisabledModule/isBtnDisabledActive"];
      },
      set(newState) {
        if (
          this.$store.getters["btnDisabledModule/isBtnDisabledActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "btnDisabledModule/toogleBtnDisabledState",
            newState
          );
        }
      },
    },

    token: {
      get() {
        return this.$store.getters["tokenModule/isTokenActive"];
      },
      set(newState) {
        if (this.$store.getters["tokenModule/isTokenActive"] !== newState) {
          this.$store.dispatch("tokenModule/toogleTokenState", newState);
        }
      },
    },

    reload: {
      get() {
        return this.$store.getters["reloadModule/isReloadActive"];
      },
      set(newState) {
        if (this.$store.getters["reloadModule/isReloadActive"] !== newState) {
          this.$store.dispatch("reloadModule/toogleReloadState", newState);
        }
      },
    },
  },
};
</script>
