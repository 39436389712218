<template>
  <div style="padding: 40pt 0">
    <v-container>
      <v-row>
        <v-col
          cols="11"
          md="6"
          style="margin: auto"
          v-for="(course, i) in coursesWrapper"
          :key="i"
        >
          <v-img :src="course[['headerPhoto']]" class="coursesSetImage">
            <v-container>
              <v-row>
                <v-col cols="6">
                  <p>{{ courseOne(course[["name"]]) }}</p>
                  <p style="font-weight: bold">
                    {{ courseTwo(course[["name"]]) }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-img>
          <div class="coursesSetDescription">
            <v-container>
              <v-row>
                <v-col cols="11" sm="6" class="modal" style="margin: auto">
                  <p style="font-size: 12pt; font-weight: bold">Duración:</p>
                  <p style="font-size: 9pt">
                    {{
                      course[["duration"]]
                        ? course[["duration"]]
                        : "Contactar a asesor"
                    }}
                  </p>
                </v-col>
                <v-col cols="11" sm="6" class="moreBtn" style="margin: auto">
                  <v-btn
                    v-on:click="
                      goToRoute(
                        defineRoute(course[['name']]),
                        defineId(course[['name']])
                      )
                    "
                  >
                    Conocer más
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "coursesWrapperComponent",

  data: () => ({}),

  updated() {
    console.log(this.coursesWrapper);
  },

  methods: {
    courseOne(name) {
      var strName = "";
      name.split(/(?=[A-Z])/).forEach((element, idx) => {
        if (idx === 0) strName = element;
      });
      return strName;
    },

    courseTwo(name) {
      var strName = "";
      name.split(/(?=[A-Z])/).forEach((element, idx) => {
        if (idx !== 0) strName += element;
      });
      return strName;
    },

    defineRoute(route) {
      var firstSplit = route.split(/(?=[A-Z])/)[0];
      var secondSplit = firstSplit.split(" ")[0];
      return secondSplit === "Licenciatura"
        ? `/carreras?career=${route}`
        : secondSplit === "Diplomado"
        ? `/diplomados?course=${route}`
        : `/preparatoria?highschool=${route}`;
    },

    defineId(id) {
      var firstSplit = id.split(/(?=[A-Z])/)[0];
      var secondSplit = firstSplit.split(" ")[0];
      return secondSplit === "Licenciatura"
        ? "#careers"
        : secondSplit === "Diplomado"
        ? "#courses"
        : "#highschool";
    },

    goToRoute(a, route) {
      this.$router.push(a);
      setTimeout(() => this.goTo(route, { duration: 300 }), 200);
    },

    goTo(target, options) {
      this.$vuetify.goTo(target, options);
    },
  },

  computed: {
    coursesWrapper: {
      get() {
        return this.$store.getters[
          "coursesWrapperModule/isCoursesWrapperActive"
        ];
      },
      set(newState) {
        if (
          this.$store.getters["coursesWrapperModule/isCoursesWrapperActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "coursesWrapperModule/toogleCoursesWrapperState",
            newState
          );
        }
      },
    },
  },
};
</script>
