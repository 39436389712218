<template>
  <div id="contact">
    <div v-if="loading">
      <LottieAnimation
        path="lottie/loader.json"
        :width="loaderWidth"
        :speed="1"
      />
    </div>
    <div v-else>
      <header-component />
      <contact-form />
      <div class="photoSeparator" />
      <chooseYourCDEComponent />
      <v-container>
        <v-row>
          <v-col cols="8" class="descriptionText contactTitle">
            Política de AHJ ENDE
          </v-col>
        </v-row>
      </v-container>
      <div style="background: #e6e6e6; padding: 25pt 0">
        <v-container>
          <v-row>
            <v-col cols="11" style="margin: auto">
              <p class="policyText">
                En el compromiso de satisfacer a plenitud a nuestros Clientes,
                la CALIDAD en la ENDE se manifiesta por la implementación de un
                sistema de gestión de la calidad y control de riesgos que se
                caracteriza por los siguientes principios:
              </p>
            </v-col>
          </v-row>
          <v-row id="policyDelimiter">
            <v-col cols="4" class="policyDelimiter" />
          </v-row>
          <v-row>
            <v-col
              cols="11"
              md="5"
              class="policyCols"
              :class="`order-${policyElement[['smallCols']]} order-md-${
                policyElement[['mediumCols']]
              }`"
              style="margin: auto"
              v-for="(policyElement, idx) in policy"
              :key="idx"
            >
              <p>{{ policyElement[["policyText"]] }}</p>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="contactDescription">
        <v-container>
          <v-row>
            <v-col cols="8" class="contactDescriptionText contactTitle">
              ¿Por qué estudiar en
              <span style="font-weight: bold"> AHJ ENDE </span>
              ?
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8" class="contactDescriptionText">
              Somos una institución comprometida con la excelencia y educación
              de vanguardia, buscamos más allá de un diploma o reconocimiento,
              que nuestros alumnos tengan experiencia práctica real basada en un
              modelo educativo con enfoque empresarial y de negocios, tu
              educación es nuestra base y compromiso.
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "../components/HeaderComponent.vue";
import contactForm from "../components/ContactForm.vue";
import chooseYourCDEComponent from "../components/chooseYourCDEComponent.vue";
import policy from "../assets/js/policy";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  name: "ContactView",

  title() {
    return `AHJ ENDE Contacto`;
  },

  data: () => ({
    loading: true,
    policy: policy,
  }),

  components: {
    LottieAnimation,
    HeaderComponent,
    contactForm,
    chooseYourCDEComponent,
  },

  created() {
    this.setPolicyColsOrder();
    this.header[["firstText"]] = "Contacto";
    this.header[["secondText"]] = "AHJ ENDE";
    this.headerPhoto =
      "https://res.cloudinary.com/interprocsysmex/image/upload/v1673847377/ahjende/contacto/Contacto_hqiq83.jpg";
    this.cde[["title"]] = "Mapa de ubicaciones";
    this.cde[["conditionalParagraph"]] =
      "Ubica tu CDE (Centro de Desarrollo Empresarial)";
    this.contact = true;
  },

  mounted() {
    setTimeout(() => {
      this.loading = false;
      setTimeout(() => {
        this.setHeaderPhoto();
        this.setContactBackground();
      }, 100);
    }, 1000);
  },

  destroyed() {
    this.contact = false;
  },

  methods: {
    setPolicyColsOrder() {
      this.policy.forEach((element, idx) => {
        element[["smallCols"]] = `${idx + 1}`;
        var middleOfList =
          (this.policy.length + 1) % 2 === 0
            ? this.policy.length / 2
            : (this.policy.length + 1) / 2;
        if (idx <= middleOfList) {
          element[["mediumCols"]] = `${1 + 2 * idx}`;
        } else {
          var evCount = idx - middleOfList - 1;
          element[["mediumCols"]] = `${3 + 2 * evCount}`;
        }
      });
    },

    setHeaderPhoto() {
      var header = window.document.getElementById("background-head");
      header.style.backgroundImage = `url(${this.headerPhoto})`;
    },

    setContactBackground() {
      var contact = window.document.getElementById("contactFormContainer");
      var policyDelimiter = window.document.getElementById("policyDelimiter");
      contact.style.background = "white";
      policyDelimiter.style.marginBottom = `${policyDelimiter.clientHeight}px`;
    },
  },

  computed: {
    loaderWidth() {
      var windowWidth = window.innerWidth;
      return windowWidth > 300 ? 300 : windowWidth;
    },

    cde: {
      get() {
        return this.$store.getters["cdeModule/isCdeActive"];
      },
      set(newState) {
        if (this.$store.getters["cdeModule/isCdeActive"] !== newState) {
          this.$store.dispatch("cdeModule/toogleCdeState", newState);
        }
      },
    },

    contact: {
      get() {
        return this.$store.getters["contactModule/isContactActive"];
      },
      set(newState) {
        if (this.$store.getters["contactModule/isContactActive"] !== newState) {
          this.$store.dispatch("contactModule/toogleContactState", newState);
        }
      },
    },

    header: {
      get() {
        return this.$store.getters["headerModule/isHeaderActive"];
      },
      set(newState) {
        if (this.$store.getters["headerModule/isHeaderActive"] !== newState) {
          this.$store.dispatch("headerModule/toogleHeaderState", newState);
        }
      },
    },

    headerPhoto: {
      get() {
        return this.$store.getters["headerPhotoModule/isHeaderPhotoActive"];
      },
      set(newState) {
        if (
          this.$store.getters["headerPhotoModule/isHeaderPhotoActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "headerPhotoModule/toogleHeaderPhotoState",
            newState
          );
        }
      },
    },
  },
};
</script>
