const state = {
  header: {
    firstText: "",
    secondText: "",
  },
};
const getters = {
  isHeaderActive(state) {
    return state.header;
  },
};
const mutations = {
  changeHeaderState(state, header) {
    state.header = header;
  },
};
const actions = {
  toogleHeaderState(context, header) {
    context.commit("changeHeaderState", header);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
