const state = {
  cdes: false,
};
const getters = {
  isCdesActive(state) {
    return state.cdes;
  },
};
const mutations = {
  changeCdesState(state, cdes) {
    state.cdes = cdes;
  },
};
const actions = {
  toogleCdesState(context, cdes) {
    context.commit("changeCdesState", cdes);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
