<template>
  <div class="courseDescriptionMainContainer">
    <v-container>
      <v-row>
        <v-col cols="11" md="6" class="order-2 order-md-1" style="margin: auto">
          <v-img
            :src="courseDescription[['img']]"
            class="courseDescriptionImage"
          />
        </v-col>
        <v-col
          cols="11"
          md="6"
          class="order-1 order-md-2 courseDescriptionContainer"
          style="margin: auto"
        >
          <div style="text-align: justify">
            <p class="coursesDescriptionTitle">
              {{ courseDescription[["title"]] }}
            </p>
            <ul v-if="courseDescription[['text']].length > 1">
              <li
                class="coursesDescriptionText"
                v-for="(paragraph, i) in courseDescription[['text']]"
                :key="i"
              >
                {{ paragraph }}
              </li>
            </ul>
            <p
              class="coursesDescriptionText"
              v-for="(paragraph, i) in courseDescription[['text']]"
              :key="i"
              v-else
            >
              {{ paragraph }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "coursesDescriptionComponent",

  data: () => ({}),

  computed: {
    courseDescription: {
      get() {
        return this.$store.getters[
          "courseDescriptionModule/isCourseDescriptionActive"
        ];
      },
      set(newState) {
        if (
          this.$store.getters[
            "courseDescriptionModule/isCourseDescriptionActive"
          ] !== newState
        ) {
          this.$store.dispatch(
            "courseDescriptionModule/toogleCourseDescriptionState",
            newState
          );
        }
      },
    },
  },
};
</script>
