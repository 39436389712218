<template>
  <div class="contactForm" id="contactFormContainer">
    <v-container>
      <v-row>
        <v-col cols="11" style="margin: auto">
          <p class="titleComponent">¡Quiero informes, planes o servicios!</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="11" sm="6" md="4" style="margin: auto">
          <v-text-field
            outlined
            :rules="[rules.required]"
            label="Nombre *"
            placeholder="Ingrese su nombre"
            v-model="form[['name']]"
            v-on:keyup.enter="getServices()"
          />
        </v-col>
        <v-col cols="11" sm="6" md="4" style="margin: auto">
          <v-text-field
            outlined
            :rules="[rules.required]"
            label="Apellido *"
            placeholder="Ingrese su apellido"
            v-model="form[['lastName']]"
            v-on:keyup.enter="getServices()"
          />
        </v-col>
        <v-col cols="11" sm="6" md="4" style="margin: auto">
          <v-text-field
            outlined
            :rules="[rules.required]"
            label="Teléfono *"
            placeholder="Ingrese su teléfono"
            v-model="form[['phone']]"
            v-on:keyup.enter="getServices()"
          />
        </v-col>
        <v-col cols="11" sm="6" md="4" style="margin: auto">
          <v-text-field
            outlined
            :rules="[rules.required, rules.email]"
            label="Correo *"
            placeholder="Ingrese su correo"
            v-model="form[['email']]"
            v-on:keyup.enter="getServices()"
          />
        </v-col>
        <v-col cols="11" sm="6" md="4" style="margin: auto">
          <v-select
            label="Selecciona tu CDE"
            :rules="[rules.required]"
            clearable
            v-model="form[['cde']]"
            item-text="cde"
            item-value="cde"
            :items="cdeItems"
            open-on-clear
            single-line
            outlined
          />
        </v-col>
        <v-col cols="11" sm="6" md="4" style="margin: auto">
          <v-select
            label="¿Qué servicio te interesa?"
            :rules="[rules.required]"
            clearable
            v-model="form[['service']]"
            item-text="service"
            item-value="service"
            :items="items"
            open-on-clear
            single-line
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="11" md="6" style="margin: auto" id="checkbox">
          <v-checkbox
            :label="`He leído y acepto el ${privacyComponent}`"
            :rules="[rules.required]"
            v-model="privacyCheck"
          />
        </v-col>
        <v-col
          cols="11"
          md="6"
          class="formsBtnColContainer"
          style="margin: auto"
        >
          <v-btn
            class="formsBtn"
            :disabled="enableButton"
            v-on:click="sendForm()"
          >
            Quiero Informes
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import rules from "../assets/js/rules";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "ContactForm",

  data: () => ({
    privacyComponent: "Aviso de Privacidad",

    privacyCheck: false,

    form: {
      name: "",
      lastName: "",
      phone: "",
      email: "",
      cde: "",
      service: "",
    },

    rules: rules,

    cdeItems: [
      {
        cde: "Ecatepec",
      },
      {
        cde: "Naucalpan",
      },
      {
        cde: "Cuautitlán",
      },
      {
        cde: "Pachuca",
      },
      {
        cde: "Querétaro",
      },
      {
        cde: "San Luis Potosí",
      },
    ],

    items: [],
  }),

  validations: {
    privacyCheck: { required },
    form: {
      name: { required },
      lastName: { required },
      phone: { required },
      email: { required, email },
      cde: { required },
      service: { required },
    },
  },

  created() {
    this.getServices();
  },

  mounted() {
    var labelContainer = window.document.querySelector("#checkbox");
    var label = labelContainer.querySelector("label");
    label.addEventListener("click", () => {
      this.privacyCheck = false;
      this.showDialog();
    });
  },

  methods: {
    showDialog() {
      this.goToRoute("/aviso-de-privacidad", "#privacy");
    },

    goToRoute(a, route) {
      this.$router.push(a);
      setTimeout(() => this.goTo(route, { duration: 300 }), 200);
    },

    goTo(target, options) {
      this.$vuetify.goTo(target, options);
    },

    getServices() {
      var endpoints = ["/courses", "/highschool", "/careers"];
      var wrapperPos = ["courses", "highschool", "careers"];
      endpoints.forEach((endpoint, idx) => {
        axios
          .get(endpoint, {
            params: {},
            headers: {},
          })
          .then((response) => {
            var data = response.data;
            var element = data[[wrapperPos[idx]]];
            element.forEach((service) => {
              this.items.push({
                service: service[["name"]],
              });
            });
          });
      });
    },

    sendForm() {
      if (!this.$v.$invalid) {
        console.log(this.form);
      } else
        alert(
          "Debes de rellenar el formulario para saber tu beca. ¡Descubre lo que AHJ ENDE tiene para tí!"
        );
    },
  },

  computed: {
    enableButton() {
      return this.$v.$invalid ? true : false;
    },
  },
};
</script>
