export default [
  {
    placeholder: "Selecciona tu estado civil actual",
    values: [
      {
        question: "Divorciado/a",
        weight: 1,
      },
      {
        question: "Casado/a - Unión Libre",
        weight: 2,
      },
      {
        question: "Soltero/a",
        weight: 3,
      },
    ],
  },
  {
    placeholder: "¿Cuál es su grado máximo de educación?",
    values: [
      {
        question: "Secundaria",
        weight: 1,
      },
      {
        question: "Preparatoria / Bachillerato",
        weight: 2,
      },
      {
        question: "Licenciatura",
        weight: 3,
      },
    ],
  },
  {
    placeholder: "Actualmente me encuentro...",
    values: [
      {
        question: "Desempleado/a",
        weight: 1,
      },
      {
        question: "Ama de casa",
        weight: 2,
      },
      {
        question: "Empleado/a",
        weight: 3,
      },
    ],
  },
  {
    placeholder: "Selecciona tu ingreso mensual dentro de los rangos",
    values: [
      {
        question: "Menos de $3,000",
        weight: 1,
      },
      {
        question: "Entre $3,000 y $5,000",
        weight: 2,
      },
      {
        question: "Más de $6,000",
        weight: 3,
      },
    ],
  },
  {
    placeholder:
      "¿Tiene dependientes económicos o personas a su cargo / cuidado?",
    values: [
      {
        question: "3 o más personas",
        weight: 1,
      },
      {
        question: "1 o 2 personas",
        weight: 2,
      },
      {
        question: "Ninguna",
        weight: 3,
      },
    ],
  },
  {
    placeholder:
      "¿Cuántos miembros conforma la familia que habitan en su hogar?",
    values: [
      {
        question: "4 o más",
        weight: 1,
      },
      {
        question: "1 - 3",
        weight: 2,
      },
      {
        question: "Vivo solo",
        weight: 3,
      },
    ],
  },
  {
    placeholder: "¿Cuál es la ocupación principal de la cabeza de la familia?",
    values: [
      {
        question: "Jubilado/a",
        weight: 1,
      },
      {
        question: "Independiente",
        weight: 2,
      },
      {
        question: "Empleado/a",
        weight: 3,
      },
    ],
  },
  {
    placeholder: "¿Tiene algún tipo de seguro? (no IMSS o similar)",
    values: [
      {
        question: "No",
        weight: 1,
      },
      {
        question: "Gastos médicos",
        weight: 2,
      },
      {
        question: "Vida",
        weight: 3,
      },
    ],
  },
  {
    placeholder: "La vivienda que habita es...",
    values: [
      {
        question: "Alquilada",
        weight: 2,
      },
      {
        question: "Propia",
        weight: 3,
      },
      {
        question: "Otro",
        weight: 1,
      },
    ],
  },
  {
    placeholder: "¿Cuál es su medio de transporte principal?",
    values: [
      {
        question: "A pie o en bicicleta",
        weight: 1,
      },
      {
        question: "Transporte público",
        weight: 2,
      },
      {
        question: "Vehículo automotor propio",
        weight: 3,
      },
    ],
  },
  {
    placeholder: "¿Cuánto tiempo ha pasado desde que dejaste de estudiar?",
    values: [
      {
        question: "2 años",
        weight: 3,
      },
      {
        question: "5 años",
        weight: 2,
      },
      {
        question: "Más de 5 años",
        weight: 1,
      },
    ],
  },
  {
    placeholder: "¿Por qué motivo pausaste tus estudios?",
    values: [
      {
        question: "Económico",
        weight: 1,
      },
      {
        question: "Familiar / salud",
        weight: 2,
      },
      {
        question: "Otro",
        weight: 3,
      },
    ],
  },
];
