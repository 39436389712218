const state = {
  headerPhoto: "",
};
const getters = {
  isHeaderPhotoActive(state) {
    return state.headerPhoto;
  },
};
const mutations = {
  changeHeaderPhotoState(state, headerPhoto) {
    state.headerPhoto = headerPhoto;
  },
};
const actions = {
  toogleHeaderPhotoState(context, headerPhoto) {
    context.commit("changeHeaderPhotoState", headerPhoto);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
