const state = {
  description: "",
};
const getters = {
  isDescriptionActive(state) {
    return state.description;
  },
};
const mutations = {
  changeDescriptionState(state, description) {
    state.description = description;
  },
};
const actions = {
  toogleDescriptionState(context, description) {
    context.commit("changeDescriptionState", description);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
