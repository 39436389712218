export default {
  required: (value) => !!value || "Este campo es requerido.",
  isNumeric: (value) => {
    return !isNaN(value) || "El valor debe ser numérico y mayor a cero.";
  },
  isCharacter: (value) => {
    let pattern = /\w/g;
    return (
      isNaN(value.match(pattern)) || "El valor no puede ser numérico o vacío."
    );
  },
  isNotZero: (value) => {
    return value >= 1 || "El valor debe ser mayor a cero";
  },
  email: (value) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || "Ingresa un correo válido";
  },
};
