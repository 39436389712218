const state = {
  scholars: false,
};
const getters = {
  isScholarsActive(state) {
    return state.scholars;
  },
};
const mutations = {
  changeScholarsState(state, scholars) {
    state.scholars = scholars;
  },
};
const actions = {
  toogleScholarsState(context, scholars) {
    context.commit("changeScholarsState", scholars);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
