const state = {
  blog: false,
};
const getters = {
  isBlogActive(state) {
    return state.blog;
  },
};
const mutations = {
  changeBlogState(state, blog) {
    state.blog = blog;
  },
};
const actions = {
  toogleBlogState(context, blog) {
    context.commit("changeBlogState", blog);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
