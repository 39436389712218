function titleChange(newTitle) {
  const { title } = newTitle.$options;
  if (title) {
    return typeof title === "function" ? title.call(newTitle) : title;
  }
}
export default {
  created() {
    const title = titleChange(this);
    if (title) {
      document.title = title;
    }
  },
};
