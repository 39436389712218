<template>
  <div id="careers">
    <div v-if="loading">
      <LottieAnimation
        path="lottie/loader.json"
        :width="loaderWidth"
        :speed="1"
      />
    </div>
    <div v-else>
      <header-component />
      <description-component />
      <courses-description-tabs-component />
      <courses-description-component />
      <contact-form />
      <chooseYourCDEComponent />
      <CDESChooserComponent />
      <courses-wrapper-component />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import HeaderComponent from "../components/HeaderComponent.vue";
import descriptionComponent from "../components/descriptionComponent.vue";
import coursesDescriptionTabsComponent from "../components/coursesDescriptionTabsComponent.vue";
import coursesDescriptionComponent from "../components/coursesDescriptionComponent.vue";
import contactForm from "../components/ContactForm.vue";
import chooseYourCDEComponent from "../components/chooseYourCDEComponent.vue";
import coursesWrapperComponent from "../components/coursesWrapperComponent.vue";
import CDESChooserComponent from "../components/CDESChooserComponent.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  name: "CareersView",

  title() {
    return `AHJ ENDE Licenciaturas`;
  },

  data: () => ({
    loading: true,
  }),

  components: {
    LottieAnimation,
    HeaderComponent,
    descriptionComponent,
    coursesDescriptionTabsComponent,
    coursesDescriptionComponent,
    contactForm,
    chooseYourCDEComponent,
    coursesWrapperComponent,
    CDESChooserComponent,
  },

  created() {
    this.getCareersData();
    this.cde[["title"]] = "Ubica tu CDE";
    this.cde[["conditionalParagraph"]] = "";
    this.courseDescriptionTabs = ["Perfil de egreso"];
  },

  methods: {
    getCareersData() {
      let _param = this.$route.query.career;
      axios
        .get("/careers", {
          params: {
            search: _param,
          },
          headers: {},
        })
        .then((response) => {
          var data = response.data;
          var career = data[["careers"]][0];
          this.description = career[["description"]];
          this.courseDescription[["title"]] = career[["alumneeTitle"]];
          this.courseDescription[["text"]] = [career["alumneeDescription"]];
          this.headerPhoto = career[["headerPhoto"]];
          this.courseDescription[["img"]] = career[["alumneePhoto"]];

          var nameStr = "";
          career[["name"]].split(/(?=[A-Z])/).forEach((element, idx) => {
            if (idx === 0) this.header[["firstText"]] = element;
            else nameStr += element;
          });
          this.header[["secondText"]] = nameStr;
        })
        .finally(() => {
          this.getSearchElements();
        });
    },

    getSearchElements() {
      axios
        .get("/courses", {
          params: {},
          headers: {},
        })
        .then((response) => {
          var data = response.data;
          this.coursesWrapper = data[["courses"]];
        })
        .finally(() => {
          this.loading = false;
          setTimeout(() => {
            this.setHeaderPhoto();
            this.setContactBackground();
            this.setDescriptionBackground();
          }, 100);
        });
    },

    setHeaderPhoto() {
      var header = window.document.getElementById("background-head");
      header.style.backgroundImage = `url(${this.headerPhoto})`;
    },

    setContactBackground() {
      var contact = window.document.getElementById("contactFormContainer");
      contact.style.background = "white";
    },

    setDescriptionBackground() {
      var description = window.document.getElementById("descriptionContainer");
      description.style.background = "black";
      description.style.color = "#c2c2c2";
    },
  },

  computed: {
    loaderWidth() {
      var windowWidth = window.innerWidth;
      return windowWidth > 300 ? 300 : windowWidth;
    },

    cde: {
      get() {
        return this.$store.getters["cdeModule/isCdeActive"];
      },
      set(newState) {
        if (this.$store.getters["cdeModule/isCdeActive"] !== newState) {
          this.$store.dispatch("cdeModule/toogleCdeState", newState);
        }
      },
    },

    courseDescription: {
      get() {
        return this.$store.getters[
          "courseDescriptionModule/isCourseDescriptionActive"
        ];
      },
      set(newState) {
        if (
          this.$store.getters[
            "courseDescriptionModule/isCourseDescriptionActive"
          ] !== newState
        ) {
          this.$store.dispatch(
            "courseDescriptionModule/toogleCourseDescriptionState",
            newState
          );
        }
      },
    },

    courseDescriptionTabs: {
      get() {
        return this.$store.getters[
          "courseDescriptionTabsModule/isCourseDescriptionTabsActive"
        ];
      },
      set(newState) {
        if (
          this.$store.getters[
            "courseDescriptionTabsModule/isCourseDescriptionTabsActive"
          ] !== newState
        ) {
          this.$store.dispatch(
            "courseDescriptionTabsModule/toogleCourseDescriptionTabsState",
            newState
          );
        }
      },
    },

    coursesWrapper: {
      get() {
        return this.$store.getters[
          "coursesWrapperModule/isCoursesWrapperActive"
        ];
      },
      set(newState) {
        if (
          this.$store.getters["coursesWrapperModule/isCoursesWrapperActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "coursesWrapperModule/toogleCoursesWrapperState",
            newState
          );
        }
      },
    },

    description: {
      get() {
        return this.$store.getters["descriptionModule/isDescriptionActive"];
      },
      set(newState) {
        if (
          this.$store.getters["descriptionModule/isDescriptionActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "descriptionModule/toogleDescriptionState",
            newState
          );
        }
      },
    },

    header: {
      get() {
        return this.$store.getters["headerModule/isHeaderActive"];
      },
      set(newState) {
        if (this.$store.getters["headerModule/isHeaderActive"] !== newState) {
          this.$store.dispatch("headerModule/toogleHeaderState", newState);
        }
      },
    },

    headerPhoto: {
      get() {
        return this.$store.getters["headerPhotoModule/isHeaderPhotoActive"];
      },
      set(newState) {
        if (
          this.$store.getters["headerPhotoModule/isHeaderPhotoActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "headerPhotoModule/toogleHeaderPhotoState",
            newState
          );
        }
      },
    },
  },
};
</script>
