const state = {
  side: false,
};
const getters = {
  isSideActive(state) {
    return state.side;
  },
};
const mutations = {
  changeSideState(state, side) {
    state.side = side;
  },
};
const actions = {
  toogleSideState(context, side) {
    context.commit("changeSideState", side);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
