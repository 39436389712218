const state = {
  map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3758.621461159961!2d-99.0470744!3d19.6007116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f1a2c35931d1%3A0xba3e6a0eab745a32!2sInstituto%20AHJ-ENDE!5e0!3m2!1ses-419!2smx!4v1681539135501!5m2!1ses-419!2smx",
};
const getters = {
  isMapActive(state) {
    return state.map;
  },
};
const mutations = {
  changeMapState(state, map) {
    state.map = map;
  },
};
const actions = {
  toogleMapState(context, map) {
    context.commit("changeMapState", map);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
