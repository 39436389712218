const state = {
  vocacional: false,
};
const getters = {
  isVocacionalActive(state) {
    return state.vocacional;
  },
};
const mutations = {
  changeVocacionalState(state, vocacional) {
    state.vocacional = vocacional;
  },
};
const actions = {
  toogleVocacionalState(context, vocacional) {
    context.commit("changeVocacionalState", vocacional);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
