const state = {
  index: false,
};
const getters = {
  isIndexActive(state) {
    return state.index;
  },
};
const mutations = {
  changeIndexState(state, index) {
    state.index = index;
  },
};
const actions = {
  toogleIndexState(context, index) {
    context.commit("changeIndexState", index);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
