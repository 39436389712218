const state = {
  scholarships: false,
};
const getters = {
  isScholarshipsActive(state) {
    return state.scholarships;
  },
};
const mutations = {
  changeScholarshipsState(state, scholarships) {
    state.scholarships = scholarships;
  },
};
const actions = {
  toogleScholarshipsState(context, scholarships) {
    context.commit("changeScholarshipsState", scholarships);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
