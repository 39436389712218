<template>
  <div class="courseCharacteristicsComponent">
    <v-container>
      <v-row>
        <v-col
          cols="11"
          sm="6"
          md="4"
          class="courseCharacteristicsCardContainer"
          style="margin: auto"
          v-for="(course, i) in courseCharacteristics"
          :key="i"
        >
          <v-card width="250px" height="250" class="courseCard">
            <v-container>
              <v-row>
                <v-col cols="11" style="margin: auto">
                  <p class="courseName">
                    {{ course[["name"]] }}
                  </p>
                </v-col>
                <v-col cols="5" class="courseSeparator" />
                <v-col cols="11" style="margin: auto">
                  <p class="courseDescription">
                    {{ course[["description"]] }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "coursesCharacteristicsComponent",

  data: () => ({}),

  computed: {
    courseCharacteristics: {
      get() {
        return this.$store.getters[
          "courseCharacteristicsModule/isCourseCharacteristicsActive"
        ];
      },
      set(newState) {
        if (
          this.$store.getters[
            "courseCharacteristicsModule/isCourseCharacteristicsActive"
          ] !== newState
        ) {
          this.$store.dispatch(
            "courseCharacteristicsModule/toogleCourseCharacteristicsState",
            newState
          );
        }
      },
    },
  },
};
</script>
