const state = {
  courseDescription: {
    title: "",
    text: [],
    img: "",
  },
};
const getters = {
  isCourseDescriptionActive(state) {
    return state.courseDescription;
  },
};
const mutations = {
  changeCourseDescriptionState(state, courseDescription) {
    state.courseDescription = courseDescription;
  },
};
const actions = {
  toogleCourseDescriptionState(context, courseDescription) {
    context.commit("changeCourseDescriptionState", courseDescription);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
