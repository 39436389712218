const state = {
  courseCharacteristics: [],
};
const getters = {
  isCourseCharacteristicsActive(state) {
    return state.courseCharacteristics;
  },
};
const mutations = {
  changeCourseCharacteristicsState(state, courseCharacteristics) {
    state.courseCharacteristics = courseCharacteristics;
  },
};
const actions = {
  toogleCourseCharacteristicsState(context, courseCharacteristics) {
    context.commit("changeCourseCharacteristicsState", courseCharacteristics);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
