const state = {
  btnDisabled: false,
};
const getters = {
  isBtnDisabledActive(state) {
    return state.btnDisabled;
  },
};
const mutations = {
  changeBtnDisabledState(state, btnDisabled) {
    state.btnDisabled = btnDisabled;
  },
};
const actions = {
  toogleBtnDisabledState(context, btnDisabled) {
    context.commit("changeBtnDisabledState", btnDisabled);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
