export default [
  [
    {
      placeholder: "1. Tratar y hablar con tacto a las personas.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "2. Ser jefe competente de un grupo, equipo o sociedad.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder:
        "3. Expresarte con facilidad en clase o al participar con tus amigos.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "4. Dibujar casas, objetos, figuras humanas, etc.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "5. Cantar en un orfeón o grupo coral.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder:
        "6.	Llevar en forma correcta y ordenada los apuntes de la clase.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "7. Entender principios y experimentos de Biología.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder:
        "8. Ejecutar con rapidez y exactitud mecanizaciones aritméticas.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder:
        "9.	Armar y componer objetos mecánicos como chapas, timbres, etc.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "10. Actividades que requieren destreza manual.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
  ],
  [
    {
      placeholder: "11. Ser miembro activo y útil en un club o sociedad.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder:
        "12. Organizar y dirigir festivales, encuentros deportivos, excursiones o campañas sociales.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "13. Redactar composiciones o artículos periodísticos.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "14.	Pintar paisajes.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "15. Aprender a tocar un instrumento musical.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder:
        "16. Ordenar y clasificar debidamente documentos de una oficina.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "17. Entender principios y experimentos de Física.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "18. Resolver problemas de aritmética.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "19. Desarmar, armar, y componer objetos complicados.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "20. Manejar con habilidad herramientas de carpintería.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
  ],
  [
    {
      placeholder: "21.	Colaborar con otros para el bien de la comunidad.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder:
        "22.	Convencer a otros para que hagan lo que tú crees que deben hacer.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "23.	Componer versos serios o jocosos.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder:
        "24.	Decorar artísticamente un salón, corredor, escenario o patio para un festival.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder:
        "25.	Distinguir cuando alguien desentona en las canciones o piezas musicales.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "26.	Contestar y redactar correctamente oficios y cartas.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "27.	Entender principios y experimentos de Química.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "28.	Resolver rompecabezas numéricos.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "29.	Resolver rompecabezas de alambre o de madera.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder:
        "30.	Manejar con facilidad herramientas mecánicas como pinzas, llave de tuercas, destornillador, etc.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
  ],
  [
    {
      placeholder:
        "31.	Saber escuchar a otros con paciencia y comprender su punto de vista.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "32.	Dar órdenes a otros con seguridad y naturalidad.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "33.	Escribir cuentos, narraciones o historietas.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "34.	Modelar con barro, plastilina o grabar madera.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "35.	Aprender a entonar correctamente las canciones de moda.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder:
        "36.	Anotar y manejar con exactitud y rapidez nombres, números y otros datos.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "37.	Entender principios y hechos económicos y sociales.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "38.	Resolver problemas de álgebra.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "39.	Armar y componer muebles.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder:
        "40.	Manejar con habilidad pequeñas piezas y herramientas como agujas, manecillas, joyas, piezas de relojería, etc.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
  ],
  [
    {
      placeholder:
        "41.	Conversar en las reuniones y fiestas con acierto y naturalidad.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder:
        "42.	Dirigir un grupo o equipo en situaciones difíciles o peligrosas.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "43.	Saber distinguir y apreciar la buena literatura.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "44.	Saber distinguir y apreciar la buena pintura.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "45.	Saber distinguir y apreciar la buena música.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder:
        "46.	Encargarse de recibir, anotar y dar recados sin olvidar detalles importantes.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder:
        "47.	Entender las causas que determinan los acontecimientos históricos.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "48.	Resolver problemas de Geometría.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder:
        "49.	Aprender el funcionamiento de ciertos mecanismos complicados como motores, relojes, bombas, etc.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder:
        "50.	Hacer con facilidad trazos geométricos con la ayuda de las escuadras, la regla 'T' y el compás.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
  ],
  [
    {
      placeholder: "51.	Actuar con 'desinterés' y condolencia.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "52.	Corregir a los demás sin ofenderlos.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder:
        "53.	Exponer juicios públicamente sin preocupación de la crítica.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder:
        "54.	Colaborar en la elaboración de un libro sobre el Arte en la Arquitectura.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "55.	Dirigir un conjunto musical.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder:
        "56.	Colaborar en el desarrollo de métodos más eficientes de trabajo.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder:
        "57.	Realizar investigaciones científicas teniendo como finalidad la búsqueda de la verdad.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "58.	Enseñar a resolver problemas de Matemáticas.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder: "59.	Inducir a la gente a obtener resultados prácticos.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
    {
      placeholder:
        "60.	Participar en un concurso de modelismo, de coches, aviones, barcos, etc.",
      values: [
        {
          question: "0 - muy incompetente",
          weight: -1,
        },
        {
          question: "1 - incompetente",
          weight: 1,
        },
        {
          question: "2 - medianamente competente",
          weight: 2,
        },
        {
          question: "3 - competente",
          weight: 3,
        },
        {
          question: "4 - muy competente",
          weight: 4,
        },
      ],
    },
  ],
];
