<template>
  <div class="courseDescriptionTabs">
    <v-container>
      <v-row>
        <v-col cols="11" style="margin: auto">
          <v-tabs center-active fixed-tabs>
            <v-tab v-for="(tab, i) in courseDescriptionTabs" :key="i">
              {{ tab }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "coursesDescriptionTabsComponent",

  data: () => ({}),

  computed: {
    courseDescriptionTabs: {
      get() {
        return this.$store.getters[
          "courseDescriptionTabsModule/isCourseDescriptionTabsActive"
        ];
      },
      set(newState) {
        if (
          this.$store.getters[
            "courseDescriptionTabsModule/isCourseDescriptionTabsActive"
          ] !== newState
        ) {
          this.$store.dispatch(
            "courseDescriptionTabsModule/toogleCourseDescriptionTabsState",
            newState
          );
        }
      },
    },
  },
};
</script>
