<template>
  <div>
    <v-container>
      <v-row style="font-weight: 300">
        <v-col cols="11" style="margin: auto">
          <div class="componentSubtitle">Crea una Noticia</div>
          <div class="subtitleDelimiter" />
        </v-col>
        <v-col
          cols="11"
          style="margin: auto"
          v-for="(key, idx) in Object.keys(news)"
          :key="idx"
        >
          <v-text-field
            :label="textField[idx][['label']]"
            :placeholder="textField[idx][['placeholder']]"
            :rules="[rules.required]"
            :disabled="btnDisabled"
            dense
            v-model="news[[key]]"
            v-on:keyup.enter="postNews()"
            v-if="key !== 'description'"
          />
          <v-textarea
            :label="textField[idx][['label']]"
            :placeholder="textField[idx][['placeholder']]"
            :rules="[rules.required]"
            :disabled="btnDisabled"
            v-model="news[[key]]"
            counter="80"
            dense
            v-on:keyup.enter="postNews()"
            v-else
          />
        </v-col>
        <v-col cols="11" style="margin: auto">
          <div
            class="droparea"
            v-on:click="getFile()"
            v-on:drop.prevent="dropEvent"
            v-on:dragover="checkDrop"
            v-on:dragenter="checkDrop"
          >
            {{ dropMessage }}
          </div>
          <input
            type="file"
            class="filePicker"
            id="filePicker"
            :disabled="btnDisabled"
            v-on:change="inputEvent"
          />
        </v-col>
        <v-col
          cols="11"
          style="text-align: left; font-weight: 300; font-size: small"
        >
          <span class="ml-8">
            * Este contenido se mostrará p&uacute;blicamente, ten cuidado con lo
            que compartas.
          </span>
        </v-col>
        <v-col cols="11" class="descriptionText applyBtn" style="margin: auto">
          <v-btn
            color="#0495b4"
            style="color: white"
            :loading="btnLoading"
            :disabled="btnDisabled"
            dense
            block
            v-on:click="uploadImageToCloudinary()"
          >
            Crear Noticia
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import rules from "../assets/js/rules";
import { required } from "vuelidate/lib/validators";

var fileLoad;

export default {
  name: "createNewsComponent",

  data: () => ({
    rules: rules,
    btnLoading: false,

    dropMessage:
      "Haz clic en esta área para subir tu imagen o arrastra y sueltala aqui.",

    textField: [
      {
        label: "Título de la noticia",
        placeholder: "Ingresa el título",
      },
      {
        label: "URL de la noticia",
        placeholder: "Ingresa la URL de la Noticia",
      },
      {
        label: "Descripción",
        placeholder: "Ingresa la descripción",
      },
    ],

    news: {
      name: "",
      link: "",
      description: "",
    },
  }),

  validations: {
    news: {
      name: { required },
      link: { required },
      description: { required },
    },
  },

  methods: {
    getFile() {
      const filePicker = window.document.getElementById("filePicker");
      filePicker.click();
    },

    checkDrop(e) {
      e.preventDefault();
    },

    dropEvent(e) {
      const droparea = window.document.querySelector(".droparea");

      const file = e.dataTransfer.files[0];
      const type = file.type;
      const size = file.size;

      if (
        (type === "image/png" ||
          type === "image/jpg" ||
          type === "image/jpeg") &&
        size < 1000000
      ) {
        return this.uploadImage(file);
      } else {
        this.dropMessage = "El archivo no es permitido, intenta con otro.";
        droparea.setAttribute("class", "droparea invalid");
        return false;
      }
    },

    inputEvent(e) {
      const droparea = window.document.querySelector(".droparea");

      const file = e.target.files[0];
      const type = file.type;
      const size = file.size;

      if (
        (type === "image/png" ||
          type === "image/jpg" ||
          type === "image/jpeg") &&
        size < 1000000
      ) {
        return this.uploadImage(file);
      } else {
        this.dropMessage = "El archivo no es permitido, intenta con otro.";
        droparea.setAttribute("class", "droparea invalid");
        return false;
      }
    },

    uploadImage(file) {
      const droparea = window.document.querySelector(".droparea");
      fileLoad = file;
      this.dropMessage = "La imagen se ha cargado correctamente";
      droparea.setAttribute("class", "droparea valid");
    },

    async uploadImageToCloudinary() {
      if (!this.$v.$invalid) {
        this.btnLoading = true;
        this.btnDisabled = true;
        var formData = new FormData();
        formData.append("file", fileLoad);
        axios
          .post(
            "https://ahjende-api-server.onrender.com/v1/stage/news/upload-file",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            this.news.image = response.data.url;
          })
          .finally(() => {
            this.postNews();
          });
      } else alert("No se puede continuar con espacios vacíos.");
    },

    async postNews() {
      if (!this.$v.$invalid) {
        this.btnLoading = true;
        const rawResponse = await fetch(
          "https://ahjende-api-server.onrender.com/v1/stage/news/register",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: this.token,
            },
            body: JSON.stringify(this.news),
          }
        );
        const content = await rawResponse.json();
        this.btnLoading = false;
        this.btnDisabled = false;
        this.news = {
          name: "",
          link: "",
          description: "",
        };
        console.log(content);
        this.reload = true;
        location.reload();
      } else
        alert("Debes de rellenar el formulario para crear una nueva Noticia");
    },
  },

  computed: {
    btnDisabled: {
      get() {
        return this.$store.getters["btnDisabledModule/isBtnDisabledActive"];
      },
      set(newState) {
        if (
          this.$store.getters["btnDisabledModule/isBtnDisabledActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "btnDisabledModule/toogleBtnDisabledState",
            newState
          );
        }
      },
    },

    token: {
      get() {
        return this.$store.getters["tokenModule/isTokenActive"];
      },
      set(newState) {
        if (this.$store.getters["tokenModule/isTokenActive"] !== newState) {
          this.$store.dispatch("tokenModule/toogleTokenState", newState);
        }
      },
    },

    reload: {
      get() {
        return this.$store.getters["reloadModule/isReloadActive"];
      },
      set(newState) {
        if (this.$store.getters["reloadModule/isReloadActive"] !== newState) {
          this.$store.dispatch("reloadModule/toogleReloadState", newState);
        }
      },
    },
  },
};
</script>
