const state = {
  coursesWrapper: [],
};
const getters = {
  isCoursesWrapperActive(state) {
    return state.coursesWrapper;
  },
};
const mutations = {
  changeCoursesWrapperState(state, coursesWrapper) {
    state.coursesWrapper = coursesWrapper;
  },
};
const actions = {
  toogleCoursesWrapperState(context, coursesWrapper) {
    context.commit("changeCoursesWrapperState", coursesWrapper);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
