const state = {
  privacy: false,
};
const getters = {
  isPrivacyActive(state) {
    return state.privacy;
  },
};
const mutations = {
  changePrivacyState(state, privacy) {
    state.privacy = privacy;
  },
};
const actions = {
  tooglePrivacyState(context, privacy) {
    context.commit("changePrivacyState", privacy);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
