const state = {
  reload: false,
};
const getters = {
  isReloadActive(state) {
    return state.reload;
  },
};
const mutations = {
  changeReloadState(state, reload) {
    state.reload = reload;
  },
};
const actions = {
  toogleReloadState(context, reload) {
    context.commit("changeReloadState", reload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
